.property-page-sidebar {
  position: fixed;
  left: 250px;
  height: 100vh;
  border: 1px solid black;
  background: white;
  padding: 10px;
  z-index: 10;
  top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: left 0.3s;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.property-page-sidebar-collapsed {
  position: fixed;
  width: 50px;
  left: 250px;
  height: 100vh;
  border: 1px solid black;
  background: white;
  padding: 10px;
  z-index: 10;
  top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: left 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.collapsed-property-page-sidebar {
  left: 60px;
}
.property-collapse-toggle {
  position: absolute;
  right: 15px;
  top: 20px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: transform 0.3s ease;
}

/* Thin scrollbar styles */
.property-page-sidebar::-webkit-scrollbar,
.property-page-sidebar-collapsed::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.property-page-sidebar::-webkit-scrollbar-thumb,
.property-page-sidebar-collapsed::-webkit-scrollbar-thumb {
  background: #aaa; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

.property-page-sidebar::-webkit-scrollbar-thumb:hover,
.property-page-sidebar-collapsed::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color on hover */
}

.property-page-sidebar::-webkit-scrollbar-track,
.property-page-sidebar-collapsed::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

/* For Firefox */
.property-page-sidebar,
.property-page-sidebar-collapsed {
  scrollbar-width: thin; /* Makes the scrollbar thin */
  scrollbar-color: #aaa #f1f1f1; /* Thumb and track colors */
}
.component-obj-lists {
  min-width: 700px;
  max-width: 1100px;
}
@media only screen and (max-width: 768px) {
  .component-obj-lists {
    flex-direction: column !important;
  }
  .building-component-box {
    width: 100% !important;
  }

  .buildingFieldContainer {
    min-height: 53px;
  }
  .building_detail_rental {
    margin-bottom: 0rem !important;
  }
}
/* Hide the default arrow */
.select-no-arrow {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
}
.title-tooltip[title] {
  position: relative;
  cursor: pointer;
}

/* [title]:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  pointer-events: none;
} */

.comp_package_table thead {
  height: 71px;
}
.comp_package_table .table thead th {
  text-transform: none;
}
.attendance-header {
  position: absolute;
  top: -45px;
  /* left: -21px; */
  font-size: 13px;
  border-bottom: 2px solid #404040;
}
.border-line {
  width: 2px;
  display: block;
  height: 56px;
  background: #404040;
}
@media (max-width: 1425px) {
  .attendance-header {
    font-size: 11px;
  }
}
