.dashboard_main {
  display: flex;
  align-items: center;
  height: 70vh;
  justify-content: center;
}
.dashboard {
  display: flex;
  width: 84%;
  height: 16rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 1rem;
}

.page_main {
  width: 45%;
  background-color: #ffffff;
  border-radius: 1.1rem 0rem 0rem 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.7rem;
}

.page_text_main {
  width: 55%;
  background-color: #f5f5f5;
  border-radius: 0rem 1.1rem 1.1rem 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.dashboard_properties_main {
  height: 4.3rem;
  width: 90%;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding-left: 0.7rem;
  cursor: pointer;
}

.dashboard_icon_main {
  width: 30%;
  display: flex;
  align-items: center;
}
.dashboard_icon {
  font-size: 2.5rem;
  color: #ed7d31;
}

.dashboard_maintenance_heading {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333f50;
}

.dashboard_first_default_heading {
  font-size: 16px;
  font-weight: 600;
  color: #333f50;
}

.dashboard_heading_main {
  /* height: 2.8rem; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.dashboard_sidebar_pic {
  margin-top: 0.5rem;
  margin-bottom: 1.1rem;
}

.active_dashboard_btn {
  border: 4px solid #333f50;
}

.maintenance_plan_icon_main {
  width: 6.2rem;
  height: 4.6rem;
  background-color: #333f50;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_analysis_main {
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.analysis_icon {
  font-size: 2.7rem;
  color: white;
}

.maintenance_analysis_heading {
  margin-top: 0.9rem;
  margin-bottom: 0rem !important;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

/* .dashboard_maintenance_main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 5rem;
} */

.dashboard_group_img_main {
  width: 45%;
  text-align: center;
}

.choose_func_head_main {
  width: 45%;
}

.dashboard_property {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .dashboard_main {
    margin-top: 3rem;
    align-items: normal;
    margin-bottom: 5rem;
  }
  .dashboard {
    box-shadow: none;
    flex-direction: column;
  }

  .dashboard_maintenance_heading {
    font-size: 0.9rem;
  }
  .page_main {
    width: 100%;
    padding: 1rem;
    border-radius: 1.1rem 1.1rem 0 0;
  }

  .dashboard_icon {
    font-size: 1.5rem;
  }
  .dashboard_first_default_heading {
    font-size: 11px;
  }
  .page_text_main {
    width: 100%;
    padding: 1rem;
    border-radius: 0rem 0rem 1.1rem 1.1rem;
  }

  .maintenance_plan_icon_main {
    height: 4rem;
    width: 4rem;
  }
  .analysis_icon {
    font-size: 1.8rem;
  }

  .maintenance_analysis_heading {
    font-size: 12px;
  }

  .dashboard_properties_main {
    height: 3rem;
  }
  .dashboard {
    width: 100% !important;
  }
}

@media (min-width: 769px) and (max-width: 1040px) {
  .dashboard_icon {
    font-size: 1.8rem;
  }
  .dashboard_maintenance_heading {
    font-size: 1rem;
  }

  .maintenance_analysis_heading {
    font-size: 13px;
  }

  .maintenance_plan_icon_main {
    width: 4rem;
    height: 4rem;
  }

  .analysis_icon {
    font-size: 1.8rem;
  }

  .dashboard_first_default_heading {
    font-size: 12px;
  }

  .dashboard_maintenance_heading {
    font-size: 0.8rem;
  }

  .dashboard_icon {
    font-size: 1.2rem;
  }

  /* .dashboard_main {
    width: 38rem;
  } */
}
