.setting_administrative {
  background-color: #333f50;
  color: #34a5d0;
  text-align: center;
  padding: 0.3rem 0;
  border-radius: 5px;
  margin-bottom: 0.7rem;
}

.setting_administrative_property_main {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.setting_administrative_main {
  width: 21.8rem;
  height: 17rem;
  overflow-y: scroll;
}

.administrative_value {
  padding: 0.6rem 0.5rem;
  margin-bottom: 0rem !important;
}
