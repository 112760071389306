.maintenence-settings-container {
  margin-left: 0 !important;
}

.maintenance_edit_item {
  float: right !important;
  margin: 0.7rem 0rem !important;
}

.maintenance_edit_item_btn {
  padding: 10px;
  width: 8rem !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  border: none;
  background-color: rgb(237, 125, 49);
}
.maintenance_edit_item_btn:hover {
  background-color: rgb(237, 125, 49);
}

.maintenance_edit_item_btn div {
  margin-right: 30px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.year_picker_field {
  width: 100% !important;
  text-align: start !important;
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #66799e;
  background-color: #ffffff;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0 10px 10px 0 !important;
}
.disabled_year_picker_field {
  background-color: #efefef4c !important;
}
.maintenance_setting_checkbox_label {
  display: block !important;
  margin-bottom: 13px !important;
}

.maintenance_setting_edit_icon {
  color: green;
  cursor: pointer;
  float: right;
  font-size: 1.2rem;
}

.datasetting_tabs {
  margin-top: 7.2rem;
}

.date-picker .year_picker_field {
  padding: 8.5px 0px 8.5px 8px;
  border-radius: 8px !important;
}
