.maintenance-package-form .package_input_field {
  text-align: start !important;
  padding: 0.25rem 0.55rem;
  font-size: 1rem;
  font-weight: 400;
  color: #66799e;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 5px !important;
  width: 100px;
}
